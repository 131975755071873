.reveal-block {
  position: absolute;
  top: 0;
  width: 100%;
  height: 101%;
  @apply bg-primary-500;
}

.reveal-block:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #c6d7e3;
  transition-property: transform;
  transition-duration: .5s
}

.reveal-block.right:before {
  transform: scaleX(0);
  transform-origin: 0 100%
}

.reveal-block.right.aos-animate:before {
  transform: scaleX(1)
}

.reveal-block.left:before {
  transform: scaleX(0);
  transform-origin: 100% 0
}

.reveal-block.left.aos-animate:before {
  transform: scaleX(1)
}

[data-aos=show-in] {
  opacity: 0
}

[data-aos=show-in].aos-animate {
  opacity: 1
}

[data-aos=reveal-right] {
  transform: scaleX(1);
  transform-origin: 100% 0;
  transition-property: transform;
  transition-delay: .5s
}

[data-aos=reveal-right].aos-animate {
  transform: scaleX(0)
}

[data-aos=reveal-left] {
  transform: scaleX(1);
  transform-origin: 0 100%;
  transition-property: transform;
  transition-delay: .5s
}

[data-aos=reveal-left].aos-animate {
  transform: scaleX(0)
}

[data-aos=reveal-item] {
  visibility: hidden;
  transition-property: visibility;
  transition-duration: 0s
}

[data-aos=reveal-item].aos-animate {
  visibility: visible
}
