// Font Face
// @include font-face('family', 'filename', 500, 'normal');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

// Body

html {
   @apply text-base;
}

body {
  @apply
     font-sans-primary
     text-gray-800
     text-optimize-legibility
     antialiased;
}

// Links

a {
   @apply transition--default;
}

// Typography

p {
   @apply mb-4;
   &:last-of-type {
      @apply mb-0;
   }
   &:empty {
      @apply hidden;
   }
}

.circle {
   @apply relative rounded-full mx-auto;
   transition: all 0.3s;
   width: 100%;
   padding-bottom: 100%;
}

.circle .text {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   margin: 0;
   text-align: center;
   font-weight: bold;
}

// Selection

::selection {
   @apply
      text-primary-500
      bg-primary-100;
}

// FOUT fix

.wf-loading {
   @apply invisible;
}

.wf-active,
.wf-inactive {
   @apply visible;
}
